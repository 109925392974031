jQuery(document).ready(function($) {
	//*** Mega menu ****//
	$(".mobile-navigation-menu").mmenu({
		offCanvas: {
			position: "right"
		},
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".nav-menu-icon").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	//*** Apply FitVids to YouTube videos ***//
	if ($("body").innerWidth() <= 767 ) {
		$(".page-section").fitVids({ ignore: ".nofit" });
	}

	//*** Scroll to top ***//
	$("#topLink").click(function() {
		$('html,body').animate({
			scrollTop: $("#Top").offset().top},
		900
		);
	});

	//*** Search form ***//
	$(".search-reveal").click(function() {
		$(".header-search-wrap").slideToggle();
		$(this).toggleClass("active");
	});

	/* eslint-disable */
	//*** Webflow Ini ***//
	Webflow.require('ix').init([
		{"slug":"nav-button","name":"nav button","value":{"style":{},"triggers":[{"type":"hover","selector":".navbar-2-menu-button-icon","preserve3d":true,"stepsA":[{"title":"Spin and Fade","opacity":0.25,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","rotateX":"0deg","rotateY":"0deg","rotateZ":"180deg"}],"stepsB":[{"opacity":1,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","rotateX":"0deg","rotateY":"0deg","rotateZ":"-180deg"}]}]}},
		{"slug":"dropdown-hover-button","name":"Dropdown Hover Button","value":{"style":{},"triggers":[{"type":"hover","selector":".nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"dropdown-hover-list","name":"Dropdown Hover List","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"dropdown-hover-arrow","name":"Dropdown Hover Arrow","value":{"style":{},"triggers":[{"type":"click","selector":".nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"desktop-nav-hover-btn","name":"Desktop Nav Hover Btn","value":{"style":{},"triggers":[{"type":"hover","selector":".desktop-nav-dropdown-list","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"desktop-nav-hover-list","name":"Desktop Nav Hover List","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"mobile-dropdown","name":"mobile dropdown","value":{"style":{"display":"none"},"triggers":[{"type":"click","stepsA":[{"title":"Open mobile dropdown","display":"block"}],"stepsB":[{"title":"Close dropdown","display":"none"}]}]}},
		{"slug":"open-dropdown-mobile-menu","name":"Open dropdown mobile menu","value":{"style":{},"triggers":[{"type":"click","selector":".nav-dropdown","siblings":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}}
	]);
	/* eslint-enable */

	// Add dropdown class to main nav items
	$(".nav-link-dd-toggle:has('.container-wrapper')").addClass("has-dropdown");
	$(".nav-link.active-item").parent(".nav-link-dd-toggle").addClass("active-li");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".nav-link-dd-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".nav-link-dd-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();
});
